/* You can add global styles to this file, and also import other style files */
@import node_modules/include-media/dist/_include-media.scss
@import ./sass/colors.sass
@import ./sass/fonts.sass

div
    display: flex

html, body, app-root
    margin: 0px
    height: 100%

body
    background-color: $primary-background-color

.content
    flex-direction: row

.noselect
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

.p-toast-message-text
    display: block

.p-toast-message-content,
p-toastitem
    width: 100%

.mb-2
    margin-bottom: .5rem !important

.icon-menu
    cursor: pointer
    flex-direction: row
    background-color: #FFF
    border: 1.5px solid #F0F2F8
    border-radius: 100%
    color: #797979
    padding: 16px
    font-size: 12px
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out
    height: 50px
    box-sizing: border-box
    justify-content: center
    max-width: 50px
    min-width: 50px
    margin-right: 10px

@media only screen and (min-width: 768px)
    .icon-menu
        display: none !important