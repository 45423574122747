@import url("https://use.typekit.net/wtp2gse.css")
@import ./colors.sass

$primary-font: Segoe UI, sans-serif

body
    font-family: $primary-font
    font-size: 14px
    letter-spacing: 0.35px

h1
    font-weight: 400
    font-size: 2.5em
    margin: 0

h2
    font-size: 20px
    font-weight: 500
    line-height: 20px
    letter-spacing: -.16px
    margin: 0px 0 20px 0

h3
    font-weight: 600
    color: $semi-gray-color
    margin: 0

.p-button-label
    font-family: $primary-font
    font-size: 12px
    line-height: 14px
    font-weight: 400

.p-inputtext,
.p-inputtextarea,
.p-dropdown
    font-family: $primary-font
    font-size: 15px
    line-height: 18px
    font-weight: 400

.p-tooltip-text
    font-family: $primary-font
    font-size: 12px
    line-height: 14px
    font-weight: 400